import React from "react";
import { Stack, Typography, Box, Button } from "@mui/material";

import { Select, Option } from "./CustomSelect";
import Input from "./Input";

const Form = (props) => {
  return (
    <Stack
      sx={{
        width: "44%",
        height: "100%",
        // border: "1px solid yellow",
        backgroundColor: "bgColor",
        borderRadius: "20px",
        padding: "1rem",
      }}
    >
      <Stack sx={{ height: "100%" }}>
        {/* kpi */}
        <Stack direction={"row"} gap={2}>
          <Typography sx={{ color: "themeColor", fontSize: "0.9rem" }}>
            Select KPI:{" "}
          </Typography>
          <Select
            value={props.defaultKPI}
            selected={props.defaultKPI}
            onChange={(e) => props.setDefaultKPI(e.target.value)}
            sx={{
              backgroundColor: "bgColor",
              border: "1px solid #3E5056",
              borderRadius: "4px",
              color: "white",
              fontSize: "0.9rem",
              padding: "0.1rem 0.25rem",
            }}
          >
            {props.allKPI &&
              props.allKPI.map((kpi) => {
                return <Option>{kpi.label}</Option>;
              })}
          </Select>
        </Stack>
        {/* kpi */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ padding: "2rem 0", borderBottom: "1px solid black" }}
        >
          {/* view options */}
          <Stack direction={"row"} gap={2}>
            <Typography sx={{ color: "themeColor", fontSize: "0.9rem" }}>
              View Options:{" "}
            </Typography>
            <Select
              value={props.defaultView}
              selected={props.defaultView}
              onChange={(e) => props.setDefaultView(e.target.value)}
              sx={{
                backgroundColor: "bgColor",
                border: "1px solid #3E5056",
                borderRadius: "4px",
                color: "white",
                fontSize: "0.9rem",
                padding: "0.1rem 0.25rem",
              }}
            >
              {props.allViews &&
                props.allViews.map((view) => {
                  return <Option>{view.label}</Option>;
                })}
            </Select>
          </Stack>
          {/* view options */}

          {/* timeperiod */}
          <Stack direction={"row"} gap={2}>
            <Typography sx={{ color: "themeColor", fontSize: "0.9rem" }}>
              Time Period:{" "}
            </Typography>
            <Select
              sx={{
                backgroundColor: "bgColor",
                border: "1px solid #3E5056",
                borderRadius: "4px",
                color: "white",
                fontSize: "0.9rem",
                padding: "0.1rem 0.25rem",
              }}
            >
              <Option>Select me</Option>
            </Select>
          </Stack>
          {/* timeperiod */}
        </Stack>

        {/* ############################################## */}
        {/* optimize for */}

        <Stack sx={{ padding: "1rem 0" }} gap={2}>
          <Typography
            sx={{
              color: "themeColor",
              fontSize: "0.9rem",
              textTransform: "uppercase",
            }}
          >
            Optimize For
          </Typography>

          {/* fixed budget */}
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <input type="radio" />
            <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
              Fixed Budget
            </Typography>
            <Input type="number" />
          </Box>
          {/* fixed budget */}

          {/* fixed spots */}
          <Box sx={{ display: "flex", gap: 2, alignItems: "start" }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <input type="radio" />
              <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                Fixed Spots
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                  Constraints
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                  Constraints
                </Typography>
                <Input type="number" />
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                  Constraints
                </Typography>
                <Input type="number" />
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                  Constraints
                </Typography>
                <Input type="number" />
              </Box>
            </Box>
          </Box>
          {/* fixed spots */}
          <Stack direction={"row"} justifyContent={"center"} gap={2}>
            <Button
              sx={{
                backgroundColor: "themeColor",
                color: "black",
                "&:hover": {
                  backgroundColor: "themeColor",
                  color: "black",
                },
              }}
            >
              Optimize
            </Button>
            <Button
              sx={{
                backgroundColor: "inputBorderColor",
                color: "white",
                "&:hover": {
                  backgroundColor: "inputBorderColor",
                  color: "white",
                },
              }}
            >
              clear
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Form;
