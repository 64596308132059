import { styled } from "@mui/material/styles";

const Input = styled("input")(({ theme, checked, refresh, inputChange }) => ({
    borderRadius: "4px",
    border: `1px solid gray`,
    backgroundColor:
      refresh || inputChange ? theme.palette.themeColor : theme.palette.bgColor, // Use the bgColor from the theme
    color:
      refresh || inputChange ? theme.palette.bgColor : theme.palette.themeColor, // Use the themeColor from the theme
    marginLeft: 0,
    width: 70,
    textAlign: "center",
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    outline: "none",
    inputProps: {
        type: "number",
      },
  
    opacity: checked ? 1 : 0.3,
  }));

  export default Input;