import { React, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Stack, Box ,Typography, useTheme, useMediaQuery} from "@mui/material";
// import axiosChartDownload from "../../axiosChartDownload";
// import { toast } from "react-toastify";
// import domtoimage from "dom-to-image";

const LTSTROI = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  const refChart = useRef(null);

  // useEffect(() => {
  //   const chart = refChart.current.chart;
  //   if (chart) chart.reflow();

  //   // return () => chart.destroy();
  // }, [props.campData]);

  const options = {
    chart: {
      zoomType: "xy",
      type: "column",
      // backgroundColor: "rgba(0,0,0,0)",
      backgroundColor: "black",
      // backgroundColor: localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" : "",
      showAxes: true,
      height: 500,
      resetZoomButton: {
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.campData.categories,

      title: {
        text: props.Xtitle,
        style: {
          color: "#FFFFFF",
        },
      },
      crosshair: false,
      labels: {
        useHTML: true,
        formatter: function () {
          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = [];
            function chnnellist(list) {
              if (list.name.split(",")[0] !== "") {
                SeriesList.push(list.name.split(",")[0]);
              }
              if ("parent" in list && list.parent !== undefined) {
                chnnellist(list.parent);
              }
            }
            chnnellist(this.axis.categories[this.pos]);

            const val = this.value.split(",");
            let title = SeriesList.reverse().join("-");
            title = title.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              } else {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              }
            } else {
              short = val[0];
            }
            return localStorage.getItem("CurrentTheme") === "Dark"
              ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
                  title +
                  ">" +
                  short +
                  "</div>"
              : '<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title=' +
                  title +
                  ">" +
                  short +
                  "</div>";
          } else {
            return localStorage.getItem("CurrentTheme") === "Dark"
              ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
                  fulltext +
                  ">" +
                  this.value.name.split(",")[0] +
                  "</div>"
              : '<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title=' +
                  fulltext +
                  ">" +
                  this.value.name.split(",")[0] +
                  "</div>";
          }
        },
        rotation: 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            props.DrillUpCamp(this);
          },
        },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      // min: 0,
      min: null,
      max: null,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.campData.ytitle,
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    // tooltip: {
    //   formatter: function () {
    //     const SeriesList = [];
    //     function chnnellist(list) {
    //       if (list.name.split(",")[0] !== "") {
    //         SeriesList.push(list.name.split(",")[0]);
    //       }
    //       if ("parent" in list && list.parent !== undefined) {
    //         chnnellist(list.parent);
    //       }
    //     }
    //     chnnellist(this.x);

    //     let label = SeriesList.reverse().join("-");
    //     return label + " " + this.y.toFixed(2) + "";
    //   },

    //   shared: true,
    //   useHTML: false,
    //   borderColor: "#ffffff",
    //   backgroundColor: "#ffffff",
    //   borderRadius: 8,
    // },

    tooltip: {
      formatter: function () {
        const SeriesList = [];
        function chnnellist(list) {
          if (list.name.split(",")[0] !== "") {
            SeriesList.push(list.name.split(",")[0]);
          }
          if ("parent" in list && list.parent !== undefined) {
            chnnellist(list.parent);
          }
        }
        chnnellist(this.x);
        // console.log("tooltip: ", this);

        let label = SeriesList.reverse().join("-");
        return label + " " + this.y.toFixed(2) + "%";
        // var series = this.series;
        // var spends = series.userOptions.spends[this.point.index];
        // var contribution = series.userOptions.contribution[this.point.index];
        // return '<b>' + label + '</b><br/>' +
        //        series.name + ': ' + this.y + '<br/>' +
        //        'Spends: ' + spends + '<br/>' +
        //        'Contribution: ' + contribution;
      },
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },

    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          format: '{point.y:.2f}%'
        },
      },
      series: {
        // cursor: 'pointer',
        point: {
          events: {
            click: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");
              // console.log("label: ", label);

              if (!props.campData.nonclicklist.includes(label)) {
                // props.DrillDown(this);
                props.DrillDownCamp(label);
              }
            },

            mouseOver: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");

              window.document.getElementsByTagName("body")[0].style.cursor =
                "pointer";
              if (props.campData.nonclicklist.includes(label)) {
                window.document.getElementsByTagName("body")[0].style.cursor =
                  "not-allowed";
              }
            },
            mouseOut: function () {
              window.document.getElementsByTagName("body")[0].style.cursor =
                "default";
            },
          },
        },
      },
    },
    legend: {
      enabled: true,
      layout: "horizontal", // Set the layout to vertical
      align: "right", // Align the legend to the right
      verticalAlign: "top", // Center the legend vertically

      itemHoverStyle: {
        color:
          localStorage.getItem("CurrentTheme") === "Dark"
            ? "#FFFFFF"
            : "#1C2427",
      },
      itemStyle: {
        color:
          localStorage.getItem("CurrentTheme") === "Dark"
            ? "#FFFFFF"
            : "#1C2427",
      },
      symbolRadius: 0,
    },
    series: props.campData.series,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: "highcharts-contextbutton",
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
        },
      },
    },
  };
  return (
    <div id="roi_chart1" style={{
      // height: "100%",
      height: "100%",
      width: (isMedium||isSmall)?"700px":"",
      overflow:(isMedium||isSmall)?"scroll":"",
      // border:"2px solid red"
    }}>
      <HighchartsReact
        ref={refChart}
        highcharts={Highcharts}
        options={options}
        {...props}
      />
    </div>
  );
};

export default LTSTROI;
