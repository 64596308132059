import React, { useState, useRef, useEffect } from "react";
 
const CheckboxDropdown = ({ AddUserMultiTabs, selectedOptionsTabs, tabNames }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // console.log(selectedOptions, "selected Options");
//   console.log("selectedOptionsTabs inside: ", selectedOptionsTabs);
//   console.log("tabNames inside: ", tabNames);

  const dropdownRef = useRef(null);
 
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
 
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
 
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
 
  return (
    <div>
      <div
        onClick={toggleDropdown}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "5px",
          backgroundColor: "#1c2427",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "#1c2427",
            color: "white",
          }}
        >
          {" "}
          <div>Tabs</div>
          <div>{isOpen ? "▲" : "▼"}</div>{" "}
        </div>
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            backgroundColor: "#1c2427",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            zIndex: 1,
            marginTop: "5px",
            overflowY: "auto",
            textAlign: "start",
            border: "1px solid #3E5056",
          }}
        >
          {tabNames?.map((option) => (
            <label key={option.value} style={{ display: "block", padding: "5px", color: "white" }}>
              <input
                type="checkbox"
                value={option.value}
                checked={selectedOptionsTabs?.includes(option?.value)}
                onChange={() => AddUserMultiTabs(option.value)}
                style={{ marginRight: "5px", color: "black" }}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
 
export default CheckboxDropdown;
 