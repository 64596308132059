// import { Stack, Typography } from "@mui/material";
import { useEffect, useRef, useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const MatchStackChart = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const chartRef = useRef(null);
  // console.log("props.stackSeries: ", props.stackSeries);
  // console.log("props.divHeight stack chart: ", props.divHeight);

  // useEffect(() => {
  //   if (chartRef.current) {
  //     chartRef.current.chart.redraw();
  //   }
  // }, [props.matchStackData, props.divHeight]);

  const refChart = useRef(null);

  const divRef = useRef(null);

  // useEffect(() => {
  //   const chart = divRef.current.chart;
  //   if (chart) chart.reflow();

  //   // return () => chart.destroy();
  // }, [props.matchStackData]);
  // console.log("stack chart2: ", props.series);
  const chartOptions = {
    credits: {
      enabled: false,
    },
    rangeSelector: {
      selected: 1,
      inputEnabled: true,
    },
    exporting: {
      enabled: false,
    },
    chart: {
      zoomType: "xy",
      type: "column",
      // backgroundColor: "#1c2427",
      backgroundColor: "black",
      height: props.divHeight - 34,
      resetZoomButton: {
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
    },
    title: {
      text: "",
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },

    tooltip: {
      shared: false,
      formatter: function () {
        return (
          "<b>" + this.x + "</b><br/>" + this.series.name + ": " + this.y + "%"
        );
      },
    },

    legend: {
      enabled: props.matchStackData.show_legend,
      layout: "horizontal", // Set the layout to vertical
      align: "right", // Align the legend to the right
      verticalAlign: "top", // Center the legend vertically
      itemStyle: {
        color: "#FFFFFF", // Set the color of legend items
      },
    },
    xAxis: {
      categories: props.matchStackData.categories,
      title: "",
      crosshair: false,
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    yAxis: {
      // min: 0,
      min: null,
      max: null,
      gridLineWidth: 0,
      // tickInterval: 0.5,
      // gridLineColor: "#7d9ca6",
      // gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.matchStackData.ytitle,
        style: {
          color: "white",
          fontSize: "14px",
        },
      },
      // stackLabels: {
      //   enabled: true,
      //   style: {
      //     fontWeight: 'bold',
      //     color: 'white'
      //   }
      // },
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    // legend: {
    //   // enabled: props.showLegend,
    //   enabled: true,
    //   // enabled: true,
    // },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
          style: {
            textOutline: "none", // Remove stroke
          },
        },
        borderWidth: 0,
        // dataLabels: {
        //   enabled: false,
        // },
        point: {
          events: {
            click: function () {
              // console.log("clickedddddddddddd: ", this.series.name);

              // props.DrillDown(this);
              if (
                !props.matchStackData.nonclicklist.includes(this.series.name)
              ) {
                props.DrillDownMatch(this);
              }
            },
            mouseOver: function () {
              // props.NotAllow.map((item) => {
              //   if (this.series.name === item) {
              //     // console.log(this.series.name, item);
              //     this.graphic.element.style.cursor = "not-allowed";
              //   } else {
              //     this.graphic.element.style.cursor = "pointer";
              //   }
              //   return "";
              // });

              if (
                props.matchStackData.nonclicklist.includes(this.series.name)
              ) {
                this.graphic.element.style.cursor = "not-allowed";
              } else {
                this.graphic.element.style.cursor = "pointer";
              }
            },
            mouseOut: function () {
              this.graphic.element.style.cursor = "default";
            },
            // function
          },
        },
      },
    },
    series: props.matchStackData.series,
    // tooltip: {
    //   formatter: function() {
    //     var series = this.series;
    //     var spends = series.userOptions.spends[this.point.index];
    //     var contribution = series.userOptions.contribution[this.point.index];
    //     return '<b>' + this.point.category + '</b><br/>' +
    //            series.name + ': ' + this.y + '%<br/>' +
    //            'Spends: ' + spends + '<br>' +
    //            'Contribution: ' + contribution
    //   }
    // },
  };

  const isSeriesEmpty = props.matchStackData?.series?.length === 0;

  useEffect(() => {
    if (
      !props.matchStackData?.series ||
      props.matchStackData.series.length === 0
    ) {
      return; // No need to initialize chart if series is empty
    }
    Highcharts.chart(divRef.current, {
      credits: {
        enabled: false,
      },
      rangeSelector: {
        selected: 1,
        inputEnabled: true,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        zoomType: "xy",
        type: "column",
        // backgroundColor: "#1c2427",
        backgroundColor: "black",
        // height: props.divHeight - 34,
        resetZoomButton: {
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },
      title: {
        text: "",
        style: {
          color: "#D6FF41",
          fontSize: "14px",
        },
      },

      tooltip: {
        shared: false,
        formatter: function () {
          return (
            "<b>" +
            this.x +
            "</b><br/>" +
            this.series.name +
            ": " +
            this.y +
            "%"
          );
        },
      },

      legend: {
        enabled: props.matchStackData.show_legend,
        layout: "horizontal", // Set the layout to vertical
        align: "right", // Align the legend to the right
        verticalAlign: "top", // Center the legend vertically
        itemStyle: {
          color: "#FFFFFF", // Set the color of legend items
        },
      },
      xAxis: {
        categories: props.matchStackData.categories,
        title: "",
        crosshair: false,
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },
      yAxis: {
        // min: 0,
        min: null,
        max: null,
        gridLineWidth: 0,
        // tickInterval: 0.5,
        // gridLineColor: "#7d9ca6",
        // gridLineDashStyle: "ShortDash",
        visible: true,
        lineWidth: 1,
        title: {
          text: props.matchStackData.ytitle,
          style: {
            color: "white",
            fontSize: "14px",
          },
        },
        // stackLabels: {
        //   enabled: true,
        //   style: {
        //     fontWeight: 'bold',
        //     color: 'white'
        //   }
        // },
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },
      // legend: {
      //   // enabled: props.showLegend,
      //   enabled: true,
      //   // enabled: true,
      // },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
            style: {
              textOutline: "none", // Remove stroke
            },
          },
          borderWidth: 0,
          // dataLabels: {
          //   enabled: false,
          // },
          point: {
            events: {
              click: function () {
                // console.log("clickedddddddddddd: ", this.series.name);

                // props.DrillDown(this);
                if (
                  !props.matchStackData.nonclicklist.includes(this.series.name)
                ) {
                  props.DrillDownMatch(this);
                }
              },
              mouseOver: function () {
                // props.NotAllow.map((item) => {
                //   if (this.series.name === item) {
                //     // console.log(this.series.name, item);
                //     this.graphic.element.style.cursor = "not-allowed";
                //   } else {
                //     this.graphic.element.style.cursor = "pointer";
                //   }
                //   return "";
                // });

                if (
                  props.matchStackData.nonclicklist.includes(this.series.name)
                ) {
                  this.graphic.element.style.cursor = "not-allowed";
                } else {
                  this.graphic.element.style.cursor = "pointer";
                }
              },
              mouseOut: function () {
                this.graphic.element.style.cursor = "default";
              },
              // function
            },
          },
        },
      },
      series: props.matchStackData?.series,
      // tooltip: {
      //   formatter: function() {
      //     var series = this.series;
      //     var spends = series.userOptions.spends[this.point.index];
      //     var contribution = series.userOptions.contribution[this.point.index];
      //     return '<b>' + this.point.category + '</b><br/>' +
      //            series.name + ': ' + this.y + '%<br/>' +
      //            'Spends: ' + spends + '<br>' +
      //            'Contribution: ' + contribution
      //   }
      // },
    });
  }, [props.matchStackData, props.divHeight]);

  return (
    <div id="trends_chartmatch" style={{ height: "100%" }}>
      {!props.matchStackData?.series ||
      props.matchStackData.series.length === 0 ? (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%", backgroundColor: "black" }}
        >
          <Typography sx={{ color: "white", fontSize: "1.5rem" }}>
            No IPL match found for the time period selected
          </Typography>
        </Stack>
      ) : (
        <div
        id="container"
        ref={divRef}
        style={{
          height: "100%",
          width: isMedium || isSmall ? "700px" : "",
          overflow: isMedium || isSmall ? "scroll" : "",
        }}
      ></div>
      )}
    </div>
  );
};

export default MatchStackChart;
