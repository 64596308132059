// import { Stack } from "@mui/material";

import CampaignChart from "./CompChart2";
import { Stack, Box ,Typography, useTheme, useMediaQuery} from "@mui/material";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
const CampaignContainer = (props) => {

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const [chartKey, setChartKey] = useState(0); // State to force chart reload

  useEffect(() => {
    // This effect will trigger whenever props.stackCategories or props.stackSeries changes
    // It will force reload the chart by updating the chartKey state
    setChartKey((prevKey) => prevKey + 1);
  }, [props.campData]);


  const [divHeight, setDivHeight] = useState("");
  console.log("divHeight stack chart: ", divHeight);


  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);


  return (
    <Stack ref={divRef} gap={1} sx={{height: "100%"}}>
      <div className="row align-items-center sorting-btn-ui">
        <div className="col-sm-3">
          <ul>
            <li onClick={props.DrillUpAllCamp} title="Drill Up">
              <i className="fa fa-arrow-up" aria-hidden="true"></i>
            </li>
            <li onClick={props.DrillDownAllCamp} title="Drill Down">
              <i className="fa fa-arrow-down" aria-hidden="true"></i>
            </li>
            <li onClick={props.DrillAllCamp} title="Expand All">
              <i className="fa fa-sitemap" aria-hidden="true"></i>
            </li>
            <li onClick={props.ResetAllCamp} title="Reset">
              <i className="fa" aria-hidden="true">
                &#8635;
              </i>
            </li>
          </ul>
        </div>
      </div>
      <div style={{
      //   backgroundColor:'red', 
      // border:"1px solid red",
      overflowx: (isSmall||isMedium)?"auto":"", /* Enable horizontal scrollbar */
      overflowy:(isSmall||isMedium)?"hidden":"", /* Hide vertical scrollbar */
      whitespace:(isSmall||isMedium)?"nowrap":"", /* Prevent line breaks */
      overflow:(isSmall||isMedium)?"scroll":""
 
    }}>
      {props.campData &&
        props.campData.series && (
          <CampaignChart
            key={chartKey}
            campData={props.campData}
            DrillDownCamp={props.DrillDownCamp}
            DrillUpCamp={props.DrillUpCamp}
            divHeight={divHeight}
          />
        )}
        </div>
    </Stack>
  );
};

export default CampaignContainer;
