import React, { useEffect, useRef } from "react";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Card from "./Card";
import "./CustomSlider.css"
import { Stack, Box , useTheme, useMediaQuery} from "@mui/material";
const SplideCarousel = ({ cards }) => {

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const splideRef = useRef(null);

  useEffect(() => {
    if (splideRef.current) {
      const splide = new Splide(splideRef.current, {
        type: "loop",
        drag: "free",
        focus: "center",
        gap: "1rem",
        perPage: isMedium||isSmall? 1:3,
        autoScroll: {
          speed: 1,
        },
      });

      // Add auto-scroll extension
      splide.mount({ AutoScroll });

      splide.on("moved", () => {
        // console.log("splide.splide.options: ", splide.Components);
        splide.Components.AutoScroll.play();
      });

      return () => {
        splide.destroy();
      };
    }
  }, [cards]);

  return (
    <div
      className="splide"
      ref={splideRef}
      style={{ width: "100%" }}
    >
      <div className="splide__track">
        <ul className="splide__list">
          {cards?.map((card, index) => {
            return (
              <li className="splide__slide">
                <Card
                  key={index}
                  header1={card.header1}
                  value={card.value}
                  header2={card.header2}
                  percent={card.percent}
                  width={"160px"}
                  height={"160px"}
                  absval={card.absval}
                  header3={card.header3}
                  basevalue={card.basevalue}
                  marginLeft={1}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(SplideCarousel);
