// import { Stack } from "@mui/material";

import CampaignChart from "./CampaignChart";

import { Select, Option } from "./CustomSelect";
import { Stack, Typography, Box, Button, SvgIcon,useMediaQuery,useTheme } from "@mui/material";

import { useEffect, useState, useLayoutEffect, useRef } from "react";
const CampaignContainer = (props) => {
  const [chartKey, setChartKey] = useState(0); // State to force chart reload

  const [divHeight, setDivHeight] = useState("");

  useEffect(() => {
    // This effect will trigger whenever props.stackCategories or props.stackSeries changes
    // It will force reload the chart by updating the chartKey state
    setChartKey((prevKey) => prevKey + 1);
  }, [props.campData]);

  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const array = [{ value: "Channel First" }, { value: "Match First" }];
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <Stack
      ref={divRef}
      gap={1}
      sx={{
        // height: props.height,
        height: (isSmall||isMedium)?"500px":props.height,
        borderTop: "1px solid black",
        paddingTop:  "0.5%",
        // border:"2px solid blue",

      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <div className="row align-items-center sorting-btn-ui"
        // style={{border:z}}
        >
          {/* className="col-sm-4" */}
          <div>
            <ul>
              <li onClick={props.DrillUpAllCamp} title="Drill Up">
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
              </li>
              <li onClick={props.DrillDownAllCamp} title="Drill Down">
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              </li>
              <li onClick={props.DrillAllCamp} title="Expand All">
                <i className="fa fa-sitemap" aria-hidden="true"></i>
              </li>
              <li onClick={props.ResetAllCamp} title="Reset">
                <i className="fa" aria-hidden="true">
                  &#8635;
                </i>
              </li>
            </ul>
          </div>
        </div>
        <Select
          value={props.defaultView}
          onChange={(e) => {
            props.setDefaultView(e.target.value);
            // props.setDisabledFlag(true);
            props.setFetchCallCamp(true);
            props.setIsSubmit(true);
          }}
          selected={props.defaultView}
          sx={{
            backgroundColor: "bgColor",
            color: "white",
            fontSize: "0.75rem",
            borderRadius: "5px",
            padding: "0.1rem 0.15rem",
            border: "1px solid #3E5056",
            
          }}
        >
          {array.map((item) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.value}
              </Option>
            );
          })}
        </Select>
      </Stack>
      <div style={{overflowx: (isSmall||isMedium)?"auto":"", /* Enable horizontal scrollbar */
      overflowy:(isSmall||isMedium)?"hidden":"", /* Hide vertical scrollbar */
      whitespace:(isSmall||isMedium)?"nowrap":"", /* Prevent line breaks */
      overflow:(isSmall||isMedium)?"scroll":"", 
      // border:"2px solid red"
      }}>
      {props.campData && props.campData.series && (
        <CampaignChart
          key={chartKey}
          campData={props.campData}
          DrillDownCamp={props.DrillDownCamp}
          DrillUpCamp={props.DrillUpCamp}
          divHeight={divHeight}
        />
      )}
      </div>
    </Stack>
  );
};

export default CampaignContainer;
