import React from "react";
// import { Box, Stack } from "@mui/material";
import Card from "./Card";
// import CarouselComponent from "./Carousel";
import CustomSlider from "./CustomSlider";
import { Box, Stack , useTheme, useMediaQuery} from "@mui/material";
const TopSection = ({ carouselData }) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
 
  const cards = carouselData?.slice(1);

  return (
    <Stack
      direction="row"
      sx={{
        // padding: "1rem 2rem",
        display:"flex",
        flexDirection:(isSmall||isMedium)?"column":"row",
        padding: isSmall||isMedium? "0.2rem 1rem":"1rem 2rem",
        backgroundColor: "bgColor",
        borderRadius: "20px 20px 0 0",
        fontFamily: "Inter, sans-serif",
        width: "100%", // Ensure the stack takes full width
        justifyContent: "space-between", // Align items horizontally
        alignItems: "center", // Align items vertically at the start
        height: "29%",
        // border:"1px solid green",
        margin: isSmall||isMedium?"3% 0% 3% 0%":"",
        // marginBottom:"4%"
        // padding:isSmall||isMedium?"1%":""
      }}
    >
      <Box 
      sx={{  width:"100%" }}
      
      >
        {" "}
        {/* Adjust width to accommodate spacing */}
        {carouselData && (
          <Card
            header1={carouselData[0]?.header1}
            value={carouselData[0]?.value}
            header2={carouselData[0]?.header2}
            percent={carouselData[0]?.percent}
            width="180px"
            height="180px"
            absval={carouselData[0]?.absval}
            header3={carouselData[0]?.header3}
            basevalue={carouselData[0]?.basevalue}
            marginLeft={1}
          />
        )}
      </Box>
      <Box
        sx={{
          width: isSmall||isMedium?"100%":"calc(100% - 1rem - 180px)",
          border: "1px solid #3E5056",
          height: "180px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          marginTop:isSmall||isMedium?"2%":""
        }}
      >
        {" "}
        {/* Adjust width to accommodate spacing */}
        {cards && (
          <Box sx={{ width: isMedium||isSmall? "calc(83% - 1rem)":"calc(90% - 1rem)",
          //  border: "4px solid blue" 
           }}>
            <CustomSlider cards={cards} />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default React.memo(TopSection);
