import React, { useEffect, useState } from "react";

import Menubar from "../../Components/Menubar";
import axios from "axios";
import baseURL from "./BaseURL";
import Loader from "./Loader";

import Form from "./Form";
import Channels from "./Channels";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import { CssBaseline, Typography } from "@mui/material";
import { Stack, Box } from "@mui/material";

let theme = createTheme({
  palette: {
    background: {
      default: "black", // Set the default background color
    },
    themeColor: "#d6ff41",
    bgColor: "#1c2427",
    inputBorderColor: "#3E5056",
  },

  typography: {
    fontFamily: ["Inter"].join(","),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme);

const Main = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [allKPI, setAllKPI] = useState([]);
  const [defaultKPI, setDefaultKPI] = useState("");
  // console.log("defaultKPI: ", defaultKPI);

  // get all kpi
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${baseURL}/optimizer_kpi/`, {});
        const data = await response.data; // Use await to get the data
        // console.log("optimizer_kpi: ", data);
        setAllKPI(data.data);
        setDefaultKPI(data.data[0].value);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // #################################
  const [allViews, setAllViews] = useState([]);
  const [defaultView, setDefaultView] = useState("");
  // console.log("defaultView: ", defaultView);

  // optimizer view
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/optimizerview_options/`,
          {}
        );
        const data = await response.data; // Use await to get the data
        // console.log("optimizer_kpi: ", data);
        setAllViews(data.data);
        setDefaultView(data.data[0].value);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // ###########################

  // #################################
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  console.log("defaultStartDate: ", defaultStartDate);
  console.log("defaultEndDate: ", defaultEndDate);

  // optimizer view
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (defaultKPI) {
        try {
          const response = await axios.post(
            `${baseURL}/optimizer_timeperiod/`,
            { kpi: defaultKPI }
          );
          const data = await response.data; // Use await to get the data
          console.log("timeperiods: ", data.data.timeperiods);
          setDefaultEndDate(data.data.timeperiods[0].enddate);
          setDefaultStartDate(data.data.timeperiods[0].startdate);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [defaultKPI]);

  return (
    <>
      {isLoading ? <Loader /> : null}

      <Menubar />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ position: "relative", top: 20 }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ height: "780px" }}
          >
            {/* left container */}
            <Form
              allKPI={allKPI}
              defaultKPI={defaultKPI}
              setDefaultKPI={setDefaultKPI}
              //
              allViews={allViews}
              defaultView={defaultView}
              setDefaultView={setDefaultView}
            />
            {/* ledt container */}

            {/* ############################################## */}
            {/* right container */}
            <Channels />
            {/* right container */}
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Main;
