// import DatePicker from "react-datepicker";
import {
  Box,
  Stack,
  Typography,
  Button,
  SvgIcon,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CustomCalendar from "./CustomCalendar";

import { Select, Option } from "./CustomSelect";

import DateInput from "./DataInput";
import ToggleButton from "react-toggle-button";

import React, { useState, useEffect } from "react";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";

const Aside = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const [none, setNone] = useState(false);

  const [validEndDate, setValidEndDate] = useState(
    new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
      ? props.defaultStartDate
      : props.defaultEndDate
  );

  useEffect(() => {
    setValidEndDate(
      new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
        ? props.defaultStartDate
        : props.defaultEndDate
    );
    props.setDefaultEndDate(
      new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
        ? props.defaultStartDate
        : props.defaultEndDate
    );
  }, [props.defaultStartDate, props.defaultEndDate]);

  // console.log("validEndDate: ", validEndDate);

  const displayNoneHandler = () => {
    setNone(!none);
  };

  const iconSummary = !none ? (
    <SvgIcon
      component={RemoveTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  ) : (
    <SvgIcon
      component={AddTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  );

  return (
    <Stack
      sx={{
        backgroundColor: "bgColor",
        padding: "0 1rem 1rem 1rem",
        width: isMedium || isSmall ? "100%" : "35%",
        borderRadius: "20px",
        height: "100%",
        gap: "1%",
        // border: "1px solid red",
      }}
    >
      <Stack
        onClick={displayNoneHandler}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
          backgroundColor: "bgColor",
          borderBottom: "1px solid black",
          // padding: "0.5rem 0",
          height: "6%",
        }}
      >
        <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
          Apply Filters
        </Typography>
        <Box
          sx={{
            color: "#d6ff41",
          }}
        >
          {iconSummary}
        </Box>
      </Stack>

      {/* time period selection start....... */}
      <Box
        sx={{
          display: none ? "none" : "block",
          height: "22%",
          borderBottom: "1px solid black",
        }}
      >
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            padding: "0.5rem 0",
          }}
        >
          <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
            Time Period:{" "}
          </Typography>

          <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
            From:{" "}
          </Typography>

          <DateInput
            type={"date"}
            value={props.defaultStartDate}
            onChange={props.setDefaultStartDate}
            min={props.startDate}
            max={props.endDate}
            disabledFlagSubmit={props.setDisabledFlag}
            setPayload={props.setPayload}
            setDisabledFlag={props.setDisabledFlag}
            setIsSubmit={props.setIsSubmit}
            setTeampayload={props.setTeampayload}
            setCityPayload={props.setCityPayload}
            setDaypartpayload={props.setDaypartpayload}
            setMatchTeamVsPayload={props.setMatchTeamVsPayload}
            setWeekflagpayload={props.setWeekflagpayload}
          />
          <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
            To:{" "}
          </Typography>

          <DateInput
            type={"date"}
            // value={props.defaultEndDate}
            value={validEndDate}
            onChange={props.setDefaultEndDate}
            min={props.defaultStartDate}
            max={props.endDate}
            disabledFlagSubmit={props.setDisabledFlag}
            setPayload={props.setPayload}
            setDisabledFlag={props.setDisabledFlag}
            setIsSubmit={props.setIsSubmit}
            setTeampayload={props.setTeampayload}
            setCityPayload={props.setCityPayload}
            setDaypartpayload={props.setDaypartpayload}
            setMatchTeamVsPayload={props.setMatchTeamVsPayload}
            setWeekflagpayload={props.setWeekflagpayload}
          />
        </Stack>
        {/* time period selection end...... */}

        {/* match selection start */}
        <Stack
          sx={{
            padding: "0.5rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              flexWrap: "wrap",
              gap: 2,
              pointerEvents:
                new Date(props.defaultEndDate) < new Date("2024-03-22")
                  ? "none"
                  : "all",
              opacity:
                new Date(props.defaultEndDate) < new Date("2024-03-22")
                  ? 0.3
                  : 1,
            }}
          >
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              Match Filters:{" "}
            </Typography>
            <Stack direction={"row"} gap={1}>
              <Select
                value={props.allTeams[0]?.value}
                onChange={(e) => {
                  props.selectTeamFromDropDown(e.target.value);
                  props.setTeampayload({ team: e.target.value });
                  props.setTeamId(e.target.value);
                  props.setDisabledFlag(false);
                  props.setIsSubmit(false);
                }}
                selected={props.allTeams[0]?.value}
                sx={{
                  backgroundColor: "bgColor",
                  color: "white",
                  fontSize: "0.75rem",
                  borderRadius: "5px",
                  padding: "0.1rem 0.15rem",
                  border: "1px solid #3E5056",
                }}
              >
                {props.allTeams &&
                  props.allTeams.map((team) => {
                    return (
                      <Option key={team.value} value={team.value}>
                        {team.label}
                      </Option>
                    );
                  })}
              </Select>
            </Stack>
            {/* </Box> */}

            {/* match selection end ...................................*/}

            {/* team vs team selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack>
                <Stack direction={"row"}>
                  <Select
                    value={props.teamMatchesVs[0]?.value}
                    onChange={(e) => {
                      props.setDefultMatchTeamVs(e.target.value);
                      props.setDisabledFlag(false);
                      props.setIsSubmit(false);
                      props.setMatchTeamVsPayload({
                        matchid: e.target.value,
                      });
                    }}
                    selected={props.teamMatchesVs[0]?.value}
                    sx={{
                      backgroundColor: "bgColor",
                      color: "white",
                      fontSize: "0.75rem",
                      borderRadius: "5px",
                      padding: "0.1rem 0.15rem",
                      border: "1px solid #3E5056",
                    }}
                  >
                    {props.teamMatchesVs &&
                      props.teamMatchesVs.map((teamVsTeam) => {
                        return (
                          <Option
                            key={teamVsTeam.value}
                            value={teamVsTeam.value}
                          >
                            {teamVsTeam.label}
                          </Option>
                        );
                      })}
                  </Select>
                </Stack>
              </Stack>
            </Box>

            {/* team vs team selection end */}

            {/* location selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultCity}
                  onChange={(e) => {
                    props.setDefaultCity(e.target.value);
                    props.setCityPayload({ city: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                  }}
                  selected={props.defaultCity}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allCities &&
                    [...new Set(props.allCities)].map((day) => (
                      <Option key={day} value={day}>
                        {day}
                      </Option>
                    ))}
                </Select>
              </Stack>
            </Box>
            {/* location selection end */}

            {/* days selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultWeekFlag}
                  onChange={(e) => {
                    props.setDefaultWeekFlag(e.target.value);
                    props.setWeekflagpayload({ wd_flag: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                  }}
                  selected={props.defaultWeekFlag}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allWeekFlag &&
                    [...new Set(props.allWeekFlag)].map((day) => (
                      <Option key={day} value={day}>
                        {day}
                      </Option>
                    ))}
                </Select>
              </Stack>
            </Box>
            {/* days selection end */}

            {/* match timing selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultDaypart}
                  onChange={(e) => {
                    props.setDefaultpart(e.target.value);
                    props.setDaypartpayload({ daypart: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                  }}
                  selected={props.defaultDaypart}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allDaypart &&
                    [...new Set(props.allDaypart)].map((time) => (
                      <Option key={time} value={time}>
                        {time}
                      </Option>
                    ))}
                </Select>
              </Stack>
            </Box>
            {/* match timing selection end */}
          </Box>

          {/* buttons reset and submit */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ paddingTop: 2 }}
          >
            <Button
              onClick={() => {
                props.setFetchCall(true); // for calender data api call
                props.setPayload({});
                props.setIsSubmit(false);
                props.setDisabledFlag(false);
                props.setTeampayload("");
                props.setCityPayload("");
                props.setDaypartpayload("");
                props.setMatchTeamVsPayload("");
                props.setWeekflagpayload("");
              }}
              sx={{
                // backgroundColor: "#d6ff41",
                border: "1px solid #d6ff41",
                fontSize: "0.75rem",
                width: "100px",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                color: "#d6ff41",
                pointerEvents:
                  new Date(props.defaultEndDate) < new Date("2024-03-22")
                    ? "none"
                    : "all",
                opacity:
                  new Date(props.defaultEndDate) < new Date("2024-03-22")
                    ? 0.3
                    : 1,
                padding: "0.1rem 0",
                "&:hover": {
                  color: "black",
                  backgroundColor: "#d6ff41", // Change color on hover
                },
              }}
            >
              match reset
            </Button>
            <Button
              onClick={() => {
                props.setFetchCall(true);
                props.setDisabledFlag(true);
                props.setIsSubmit(true);
                props.setFetchCallTrends(true);
                props.setFetchCallBaseline(true);
                props.setFetchCallCamp(true);
                props.setFetchCallLine(true);
                props.setFetchCallFunnel(true);
                props.setFetchCallMatchStack(true);
              }}
              disabled={props.disabledFlag}
              sx={{
                // backgroundColor: props.disabledFlag ? "" : "#d6ff41",
                fontSize: "0.75rem",
                width: "100px",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                padding: "0.1rem 0",
                color: "#d6ff41",
                border: !props.disabledFlag
                  ? "1px solid #d6ff41"
                  : "1px solid #3E5056",
                "&:hover": {
                  color: "black",
                  backgroundColor: "#d6ff41", // Change color on hover
                },
              }}
            >
              submit
            </Button>
          </Stack>
          {/* button reset and submit end......... */}
        </Stack>

        {/* match selection end */}
      </Box>

      {/* time perid selection end */}

      {/* bottom 2nd part start */}
      <Stack sx={{ height: none ? "93%" : "70%" }}>
        {/* ........................... */}
        {/* kpi selection start */}
        <Box
          sx={{
            height: isSmall || isMedium ? "65px" : "6%",
            display: "flex",
            flexDirection: isSmall || isMedium ? "column" : "row",
            // flexDirection:"row",
            justifyContent: "space-between",
            alignItems: "center",
            // border:"2px solid yellow",
            padding: isSmall || isMedium ? "2%" : "",}}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={2}
            sx={
              {
                // padding: "0.5rem 0",
                width:"100%"
              }
            }
          >
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              Business KPI:{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                direction: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box
                direction={"column"}
                gap={1}
                sx={{ backgroundColor: "bgColor" }}
              >
                <Stack>
                  <Stack direction={"row"} gap={1}>
                    <Select
                      value={props.defaultKpiFilter}
                      onChange={(e) => {
                        props.selectKpiFilterFromDropDown(e.target.value);
                        // props.setPayload({});
                        props.setDisabledFlag(true);
                        props.setFetchCallTrends(true);
                        props.setFetchCallCamp(true);
                        props.setIsSubmit(true);
                        props.setFetchCall(true);
                        props.setFetchCallLine(true);
                        props.setFetchCallBaseline(true);
                        props.setFetchCallFunnel(true);
                        props.setFetchCallMatchStack(true);
                      }}
                      selected={props.defaultKpiFilter}
                      sx={{
                        backgroundColor: "bgColor",
                        color: "white",
                        fontSize: "0.75rem",
                        borderRadius: "5px",
                        padding: "0.1rem 0.15rem",
                        border: "1px solid #3E5056",
                      }}
                    >
                      {props.kpiFilterList &&
                        props.kpiFilterList.map((kpi) => {
                          return (
                            <Option key={kpi.value} value={kpi.value}>
                              {kpi.label}
                            </Option>
                          );
                        })}
                    </Select>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Stack>

          {/* kpi selection end */}
          {/* ........................... */}
          {/* toggle start */}
          <Stack direction={"row"} gap={1}
           justifyContent= {isSmall||isMedium?"flex-start":"flex-end"}
            style={{
              // border:"2px solid red",
                        width:"100%"
          }}
          >
            <Typography
              sx={{
                color: !props.toggle ? "#d6ff41" : "white",
                fontSize: "0.85rem",
                // opacity: !props.toggle ? 1 : 0.3,
              }}
            >
              Absolute
            </Typography>
            <ToggleButton
              value={props.toggle}
              inactiveLabel=""
              activeLabel=""
              onToggle={(value) => {
                props.setToggle(!value);
                props.setFetchCall(true);
                props.setIsSubmit(true);
              }}
              colors={{
                activeThumb: {
                  base: "#d6ff41",
                },
                inactiveThumb: {
                  base: "#d6ff41",
                },
                active: {
                  base: "#3E5056",
                  // hover: "rgb(177, 191, 215)",
                },
                inactive: {
                  base: "#3E5056",
                  // hover: "rgb(95,96,98)",
                },
              }}
            />
            <Typography
              sx={{
                color: props.toggle ? "#d6ff41" : "white",
                fontSize: "0.85rem",
                // opacity: props.toggle ? 1 : 0.3,
              }}
            >
              Incremental
            </Typography>
          </Stack>
        </Box>

        {/* toggle end */}
        {props.defaultStartDate &&
          props.defaultEndDate &&
          props.calenderData && (
            <CustomCalendar
              startDate={new Date(props.defaultStartDate)}
              endDate={new Date(props.defaultEndDate)}
              calenderData={props.calenderData}
              toggle={props.toggle}
              isSubmit={props.isSubmit}
              height={"94%"}
              none={none}
            />
          )}
      </Stack>
    </Stack>
  );
};

export default React.memo(Aside);
