import { useEffect, useRef, useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { Stack, Box ,Typography, useTheme, useMediaQuery} from "@mui/material";

// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const ColumnLineChart = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  console.log("props.baseLineData[0]: ", props.baseLineData[0]?.incremental_unit);
  // console.log("props.baselineseries: ", props.baseLineSeries);

  const [divHeight, setDivHeight] = useState("");
  console.log("divHeight column line chart: ", props.divHeight);

  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  function colors(index) {
    if (index === 0) {
      return "#2563eb";
    } else if (index === 1) {
      return "#f97316";
    } else if (index === 2) {
      return "#d6ff41";
    }
  }

  function zIndex(index) {
    if (index === 0) {
      return 3;
    } else if (index === 1) {
      return 3;
    } else if (index === 2) {
      return 0;
    }
  }

  let incrementalAdded = false;
  useEffect(() => {
    Highcharts.chart(divRef.current, {
      chart: {
        zoomType: "xy",
        // backgroundColor: "#1c2427",
        backgroundColor: "black",
        // height: props.divHeight,
        resetZoomButton: {
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },
      yAxis: {
        title: {
          text: props.baseLineData[0]?.ytitle,
          style: {
            color: "#FFFFFF", // Set color of y-axis ticks
          },
        },
        gridLineWidth: 0,
        labels: {
          style: {
            color: "#FFFFFF", // Set color of y-axis ticks
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: "",
        align: "left",
        style: {
          color: "#FFFFFF", // Color for the chart title
        },
      },
      subtitle: {
        // Add your subtitle if needed
      },
      xAxis: {
        categories: props.baseLineData[0]?.xAxis.categories,
        // title: props.xtitle,
        crosshair: false,
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },

      // tooltip: {
      //   shared: true,
      //   formatter: function () {
      //     // console.log(" this.points[0]: ", this.points);
      //     var tooltip = "<b>" + this.x + "</b><br/>";
      //     var baselinePer = this.points[0].y;
      //     var baseline = this.points[1].y;
      //     var total = this.points[2].y;
      //     var Incremental = total - baselinePer;
      //     tooltip +=
      //       // this.points[0].series.userOptions.tooltip.valueSuffix +
      //       // "<br/>" +
      //       "Incremental: " +
      //       Incremental.toFixed(2) + " " + this.points[0].series.userOptions.tooltip.valueSuffix +

      //       "<br/>" +
      //       "Total: " +
      //       total + " " + this.points[0].series.userOptions.tooltip.valueSuffix +

      //       "<br/>" +
      //       "Base + Perf: " +
      //       baselinePer + " " + this.points[0].series.userOptions.tooltip.valueSuffix +

      //       "<br/>" +
      //       "Base: " +
      //       baseline + " " + this.points[0].series.userOptions.tooltip.valueSuffix
      //       ;
      //     return tooltip;
      //   },
      // },

      tooltip: {
        shared: true,
        formatter: function() {
            var tooltip = '<b style="color: black;">' + this.x + '</b><br>',
                series = this.points;
    
            series.forEach(function(point) {
                // Add incremental value only once
                if (!incrementalAdded) {
                    tooltip += '<span style="color: black;"><b>Incremental: ' + props.baseLineData[0]?.incremental[point.point.index] + " " + props.baseLineData[0]?.incremental_unit + '</b></span><br>';
                    incrementalAdded = true; // Set flag to true after adding incremental value
                }
                tooltip += '<span style="color: black;"><b>' + point.series.name + '</b></span>: <b>' + point.y + " " + props.baseLineData[0]?.incremental_unit + '</b><br>';
    
            });
            incrementalAdded = false; // Reset flag for next tooltip
            return tooltip;
        }
    },
    

      legend: {
        enabled: true,
        layout: "horizontal", // Set the layout to vertical
        align: "right", // Align the legend to the right
        verticalAlign: "top", // Center the legend vertically
      },
      plotOptions: {
        column: {
          borderColor: "none",
          color: "#d6ff41",
        },
        spline: {
          color: "blue",
        },
      },
      series: props.baseLineSeries?.map((series, index) => ({
        name: series.name,
        type: series.type,
        data: series.data,
        zIndex: zIndex(index),
        tooltip: series.tooltip,
        color: colors(index),
      })),
    });
  }, [props.baseLineData, props.baseLineSeries]);

  return <div style={{
    //   backgroundColor:'red', 
    // border:"1px solid red",
    overflowx: (isSmall||isMedium)?"auto":"", /* Enable horizontal scrollbar */
    overflowy:(isSmall||isMedium)?"hidden":"", /* Hide vertical scrollbar */
    whitespace:(isSmall||isMedium)?"nowrap":"", /* Prevent line breaks */
    overflow:(isSmall||isMedium)?"scroll":""

  }}>
    <div id="container" ref={divRef} style={{ 
  height: "100%",
  width: (isMedium||isSmall)?"700px":"",
  overflow:(isMedium||isSmall)?"scroll":"",
  }}></div>
  </div>;
};

export default ColumnLineChart;
