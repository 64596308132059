import React, { useEffect, useState } from "react";

import Menubar from "../../Components/Menubar";
import axios from "axios";
import baseURL from "./BaseURL";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import { CssBaseline, Typography } from "@mui/material";
// import { Stack, Box } from "@mui/material";
import { Stack, Box , useTheme, useMediaQuery} from "@mui/material";
import MainSection from "./MainSection";
import Aside from "./Aside";

import Loader from "./Loader";
import StackContainer from "./StackCont2";
import CampaignContainer from "./CompCont2";
import LineChart from "./LineChart2";
import FunnelChart from "./FunnelChart2";
import MatchStackChartContainer from "./MatchStackCont2";

let theme = createTheme({
  palette: {
    background: {
      default: "black", // Set the default background color
    },
    themeColor: "#d6ff41",
    bgColor: "#1c2427",
    inputBorderColor: "#3E5056",
  },

  typography: {
    fontFamily: ["Inter"].join(","),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  // states for team...................
  const [defaultTeam, setDefaultTeam] = useState("");
  // console.log("defaultTeam: ", defaultTeam);
  const [teamPayload, setTeampayload] = useState("");
  // console.log("teamPayload: ", teamPayload);

  // states for cities
  const [defaultCity, setDefaultCity] = useState();
  // console.log("defaultCity: ", defaultCity);
  const [allCities, setAllCities] = useState([]);
  // console.log("allCities: ", allCities);
  const [cityPayload, setCityPayload] = useState("");
  // console.log("cityPayload: ", cityPayload);

  // states for daypart

  const [defaultDaypart, setDefaultpart] = useState("");
  // console.log("defaultDaypart: ", defaultDaypart);
  const [allDaypart, setAllDaypart] = useState([]);
  // console.log("allDaypart: ", allDaypart);
  const [dayPartpayload, setDaypartpayload] = useState("");

  // states for descriptions team1 vs team2
  const [defaultMatchTeamVs, setDefultMatchTeamVs] = useState("");
  // console.log("defaultMatchTeamVs: ", defaultMatchTeamVs);
  const [matchTeamVsPayload, setMatchTeamVsPayload] = useState("");

  // states for week flag
  const [defaultWeekFlag, setDefaultWeekFlag] = useState("");
  // console.log("defaultWeekFlag: ", defaultWeekFlag);
  const [allWeekFlag, setAllWeekFlag] = useState([]);
  // console.log("allWeekFlag: ", allWeekFlag);
  const [weekFlagpayload, setWeekflagpayload] = useState("");

  // states for timing

  // states for timeperiod
  const [defaultStartDate, setDefaultStartDate] = useState("");
  // console.log("defaultStartDate: ", defaultStartDate);
  const [defaultEndDate, setDefaultEndDate] = useState("");
  // console.log("defaultEndDate: ", defaultEndDate);
  const [endDate, setEndDate] = useState("");
  // console.log("endDate: ", endDate);
  const [startDate, setStartDate] = useState("");
  // console.log("startDate: ", startDate);

  // states for Team end...............

  // states for kpi filter start
  const [defaultKpiFilter, setDefaultKpiFilter] = useState("");
  // console.log("defaultKpiFilter: ", defaultKpiFilter);
  const [kpiFilterList, setKpiFilterList] = useState([]);
  // console.log("kpiFilterList: ", kpiFilterList);

  // toggel in calender
  const [toggle, setToggle] = useState(true);
  // console.log("toggle: ", toggle);

  // payload for filters api call
  const [payload, setPayload] = useState({});

  const [allTeams, setAllTeams] = useState([]);
  const [teamId, setTeamId] = useState("");
  // console.log("teamId: ", teamId);
  const [teamMatchesVs, setTeamMatchesVs] = useState([]);
  // console.log("teamMatchesVs: ", teamMatchesVs);

  const [calenderData, setCalenderData] = useState([]);
  // console.log("calenderData: ", calenderData);

  // for calender
  const [fetchCall, setFetchCall] = useState(true);

  // stack chart
  const [stackSeries, setStackSeries] = useState([]);
  // console.log("stackSeries: ", stackSeries);
  const [stackCategories, setStackCategories] = useState([]);
  // console.log("stackCategories: ", stackCategories);
  const [stackNonclickList, setStackNonclickList] = useState([]);
  // console.log("stackNonclickList: ", stackNonclickList);
  const [chartData, setChartData] = useState({});
  const [chartDataMatchStack, setChartDataMatchStack] = useState({});
  // console.log("chartData: ", chartData);
  const [fetchCallTrends, setFetchCallTrends] = useState(true);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);
  const [reset, setIsReset] = useState(0);
  const [BreadCrumbsTrends, setBreadCrumbsTrends] = useState([]);
  // for stack cahrt end...............................

  const [BreadCrumbsMatch, setBreadCrumbsMatch] = useState([]);
  // for campaign chart
  const [campData, setCampData] = useState([]);
  const [chartDataCamp, setChartDataCamp] = useState({});
  const [fetchCallCamp, setFetchCallCamp] = useState(true);
  const [isAllCamp, setIsAllCamp] = useState(0);
  const [resetCamp, setResetCamp] = useState(0);
  const [BreadCrumbsCamp, setBreadCrumbsCamp] = useState([]);

  // for buttons
  const [disabledFlag, setDisabledFlag] = useState(true);
  // console.log("disabledFlag: ", disabledFlag);

  const [carouselData, setCarouselData] = useState();

  const [tabNames, setTabNames] = useState([]);
  // console.log("tabNames: ", tabNames);
  const [tabId, setTabId] = useState([]);
  // console.log("tabId: ", tabId);
  const [isLoading, setIsLoading] = useState(false);
  // console.log("isLoading: ", isLoading);

  // for all api call for calender on submit button by default submit is true......
  const [isSubmit, setIsSubmit] = useState(true);

  // console.log("isSubmit: ", isSubmit);

  const [activeTab, setActiveTab] = useState("");
  // console.log("activeTab: ", activeTab);

  const [fetchCallBaseline, setFetchCallBaseline] = useState(true);

  const [fetchCallLine, setFetchCallLine] = useState(true);
  const [lineData, setLineData] = useState([]);
  // console.log("lineData: ", lineData);

  const [primaryAxisPayload, setPrimaryAxisPayload] = useState([]);

  function extractValues(jsonData) {
    const valuesArray = [];
    jsonData.forEach((item) => {
      valuesArray.push(item.value);
    });
    return valuesArray;
  }

  function extractTabShortValues(data) {
    const tabShortArray = [];

    data.forEach((item) => {
      tabShortArray.push(item.tab_short);
    });

    return tabShortArray;
  }

  // console.log("chartData: ", chartData);
  // console.log("LineChart: ", LineChart);

  // states for kpi filter end...............
  const selectTeamFromDropDown = (value) => {
    setDefaultTeam(value);
  };

  const selectKpiFilterFromDropDown = (value) => {
    setDefaultKpiFilter(value);
  };

  // paylaod for filters api
  useEffect(() => {
    // Set timeout to hide the loader after 5 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Clear timeout if the component unmounts before the 5 seconds
    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    setPayload({
      ...teamPayload,
      ...cityPayload,
      ...dayPartpayload,
      ...matchTeamVsPayload,
      ...weekFlagpayload,
    });
  }, [
    teamPayload,
    cityPayload,
    dayPartpayload,
    matchTeamVsPayload,
    weekFlagpayload,
  ]);

  // post all filters........................................
  useEffect(() => {
    const fetchData = async () => {
      if (defaultStartDate && defaultEndDate) {
        try {
          const response = await axios.post(`${baseURL}/filterall/`, {
            start_date: defaultStartDate,
            end_date: defaultEndDate,
            matchfilters: payload,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setAllTeams(data.teamdict);
          setTeamId(data.teamdict[0].value);
          setDefaultTeam(data.teamdict[0].value);

          setDefaultCity(data.city[0]);
          setAllCities(data.city);

          setDefaultpart(data.daypart[0]);
          setAllDaypart(data.daypart);

          setTeamMatchesVs(data.matchdict);
          setDefultMatchTeamVs(data.matchdict[0].value);

          setDefaultWeekFlag(data.wd_flag[0]);
          setAllWeekFlag(data.wd_flag);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [defaultStartDate, defaultEndDate, payload]);

  // calender view
  useEffect(() => {
    const fetchData = async () => {
      if (
        fetchCall &&
        defaultStartDate &&
        defaultCity &&
        defaultDaypart &&
        defaultEndDate &&
        payload &&
        defaultKpiFilter &&
        teamId &&
        defaultMatchTeamVs &&
        defaultWeekFlag &&
        isSubmit
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/calenderview/`, {
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            kpi: defaultKpiFilter,
            team1: teamId,
            match_id: defaultMatchTeamVs,
            weekend_flag: defaultWeekFlag,
            city: defaultCity,
            time: defaultDaypart,
            togid: toggle ? "Incremental" : "Absolute",
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setCalenderData(data);
          // setFetchCall(false);
          // setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          // setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    defaultStartDate,
    defaultCity,
    defaultDaypart,
    defaultEndDate,
    payload,
    defaultKpiFilter,
    teamId,
    defaultMatchTeamVs,
    defaultWeekFlag,
    fetchCall,
    toggle,
    isSubmit,
  ]);

  // post timeperiod
  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/timeperiod/`, {
          tabid: "overview",
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setDefaultStartDate(data.data.timeperiods[0].defaultstartdate);
        setDefaultEndDate(data.data.timeperiods[0].defaultenddate);
        setStartDate(data.data.timeperiods[0].startdate);
        setEndDate(data.data.timeperiods[0].enddate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // post tabname
  useEffect(() => {
    const fetchData = async () => {
      if (defaultKpiFilter) {
        try {
          const response = await axios.post(`${baseURL}/overviewtab/`, {
            kpi: defaultKpiFilter,
            start_data: defaultStartDate,
            end_date: defaultEndDate,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setTabNames(data.data.tabs);
          setTabId(extractTabShortValues(data.data.tabs));
          setActiveTab(data.data.tabs[0].tab_short);
          // setActiveTab("tab1");
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [defaultKpiFilter]);

  // get kpi filters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/kpi_filter/`, {
          tabid: "overview",
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setDefaultKpiFilter(data.kpi.default_value.value);
        setKpiFilterList(data.kpi.all_value);
        setPrimaryAxisPayload(extractValues(data.kpi.all_value));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // get channel filter
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/channelfilter/`, {});
        const data = await response.data; // Use await to get the data
        // console.log("channel filters: ", data);
        setChartData(convertToChartData(data.data));
        setChartDataCamp(convertToChartData(data.data));
        setChartDataMatchStack(convertToChartData(data.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function convertToChartData(originalData) {
    const chartdata = {};

    originalData.forEach((section) => {
      const sectionData = {
        selection: [],
        // Filter: section.values.map(item => item.value)
        Filter: [],
      };
      chartdata[section.name] = sectionData;
    });

    return chartdata;
  }
  // stack chart api

  // drill down for point click in trend

  const DrillDownTrends = (Category) => {
    // for point click
    // console.log("Category: ", Category.series.name);

    const arr = Category.series.name.split("-");
    const Length = arr.length - 1;

    // console.log("Length: ", Length);

    const keysArrayOfChartData = Object.keys(chartData);
    console.log("keysArrayOfChartData: ", keysArrayOfChartData);

    const keyToAddSelectionFilter = keysArrayOfChartData[Length];

    console.log("keyToAddSelection: ", keyToAddSelectionFilter);

    let resultObject = structuredClone(chartData);
    // console.log("resultObject: ", resultObject);

    if (resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection.push(
        Category.series.name
      );
    }

    setChartData(resultObject);
    setFetchCallTrends(true);

    // console.log("resultObject: ", resultObject);
  };

  // for tree button
  const DrillAllTrends = () => {
    // for tree button
    let ChartdataTemp = {};
    for (const filter in chartData) {
      ChartdataTemp[filter] = chartData[filter];
      ChartdataTemp[filter].selection = [];
    }

    // new code ................
    const keysArrayOfChartData = Object.keys(chartData);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTrendsTemp = structuredClone(chartData);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

    result.forEach((item) => {
      return item.push(...stackNonclickList);
    });

    // console.log("resulted array: ", result)

    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });

      //  return itemArray;
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData
      .slice(0, keysArrayOfChartData.length - 1)
      .forEach((key, index) => {
        if (ChartdataTrendsTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTrendsTemp[key].selection = payloadArray[index];
        }
      });

    // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);

    // new code ended..............

    // let BreadCrumbsTrendsTemp = [];
    // BreadCrumbsTrendsTemp =  Object.keys(chartData);

    // setBreadCrumbsTrends(BreadCrumbsTrendsTemp);

    setIsAllEnabled(1);
    // setChartdataTrends(ChartdataTemp);
    setChartData(ChartdataTrendsTemp);
    setFetchCallTrends(true);
  };

  // for reset button
  const ResetAllTrends = () => {
    // fro reset button
    let ChartdataTemp = {};
    for (const filter in chartData) {
      ChartdataTemp[filter] = chartData[filter];
      ChartdataTemp[filter].selection = [];
    }

    let BreadCrumbsTrendsTemp = [];
    BreadCrumbsTrendsTemp = Object.keys(chartData);

    // setBreadCrumbsTrends([BreadCrumbsTrendsTemp[0]]);
    setIsReset(1);
    setIsAllEnabled(0);
    setChartData(ChartdataTemp);
    setFetchCallTrends(true);
  };

  // for down arrow
  const DrillDownAllTrends = () => {
    // for down arrow

    // console.log("is all enabled: ", IsAllEnabled);

    const arr = [];
    const seriesName = stackSeries.series.map((item) => {
      arr.push(item.name.split("-").length);
      return item.name;
    });

    const minLength = Math.min(...arr);

    const payloadSeriesName = seriesName.map((item) => {
      const newItem = item.split("-");

      const a = newItem.slice(0, minLength);
      const b = a.join("-");

      return b;
    });
    // console.log("seriesTrends: ", seriesTrends);
    // console.log("Not allow list: ", NotAllow);
    // console.log("clicked drill down all series payload: ", payloadSeriesName);
    // console.log("series array length: ", minLength - 1);

    const keysArrayOfChartData = Object.keys(chartData);

    // console.log("keysArrayOfChartData: ", keysArrayOfChartData.length, minLength);

    const keyToAddSelectionFilter = keysArrayOfChartData[minLength - 1];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);

    let resultObject = structuredClone(chartData);

    if (
      minLength < keysArrayOfChartData.length &&
      resultObject.hasOwnProperty(keyToAddSelectionFilter)
    ) {
      resultObject[keyToAddSelectionFilter].selection = payloadSeriesName;
    }
    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, minLength + 1));
    setChartData(resultObject);
    setFetchCallTrends(true);
  };

  // for up arrow
  const DrillUpAllTrends = () => {
    // console.log("drill up all called: ")

    const arr = [];
    const seriesName = stackSeries.series.map((item) => {
      arr.push(item.name.split("-").length);
      return item.name;
    });

    const maxLength = Math.max(...arr);

    const payloadSeriesName = seriesName.map((item) => {
      const newItem = item.split("-");

      const a = newItem.slice(0, maxLength - 2);
      const b = a.join("-");

      return b;
    });
    // console.log("clicked drill up all series payload: ", payloadSeriesName);
    // console.log("series array length: ", maxLength - 1);

    const keysArrayOfChartData = Object.keys(chartData);
    // console.log("keysArrayOfChartData: ", keysArrayOfChartData);
    const BreadCrumbsTrendsLength = BreadCrumbsTrends.length;

    // console.log("BreadCrumbsTrendsLength: ", BreadCrumbsTrendsLength);
    const keyToAddSelectionFilter = keysArrayOfChartData[maxLength - 3];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);
    // console.log("keys array length: ", keysArrayOfChartData.length);

    const keyToAddSelectionFilterIndex = keysArrayOfChartData.indexOf(
      keyToAddSelectionFilter
    );
    // console.log("keys array index: ", keyToAddSelectionFilterIndex)

    let resultObject = structuredClone(chartData);

    const removeDuplicates = (arr) => {
      const uniqueArray = arr.filter((item, index) => {
        return arr.indexOf(item) === index;
      });

      return uniqueArray;
    };

    // Example usage
    let updatedPayload = removeDuplicates(payloadSeriesName);

    // console.log("updatedPayload in drill up: ", JSON.stringify(updatedPayload));

    if (JSON.stringify(updatedPayload) === '[""]') {
      updatedPayload = [];
    }

    if (keyToAddSelectionFilterIndex === -1) {
      let KEY = keysArrayOfChartData[0];
      if (resultObject.hasOwnProperty(KEY)) {
        resultObject[KEY].selection = updatedPayload;
        // console.log("[Fixed[0].id]: ", [Fixed[0].id]);
      }
      setBreadCrumbsTrends([KEY]);
    } else if (resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection = updatedPayload;
      let start = keyToAddSelectionFilterIndex + 1;
      for (let i = start; i < BreadCrumbsTrendsLength; i++) {
        resultObject[keysArrayOfChartData[i]].selection = [];
      }
      // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, maxLength - 1));
    }
    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, maxLength - 1));
    setChartData(resultObject);
    setFetchCallTrends(true);
  };

  // prepare breacrumbs trends
  useEffect(() => {
    const CHART_DATA = [structuredClone(chartData)];
    const [cloneOfChartdata] = CHART_DATA;
    // console.log("cloneOfChartdata: ", cloneOfChartdata);
    const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
    const listToStoreLength = [];
    for (const key in cloneOfChartdata) {
      const list = cloneOfChartdata[key]["selection"];
      // console.log("list: ", list);
      if (list.length === 0) {
        break;
      }
      for (const name of list) {
        const arr = name.split("-");
        // console.log("arr: ", arr);
        listToStoreLength.push(arr.length);
      }
    }
    // console.log("listToStoreLength: ", listToStoreLength);

    let maxNumber = Math.max(...listToStoreLength);

    if (listToStoreLength.length === 0) {
      maxNumber = 0;
    }
    // console.log("maxNumber: ", maxNumber);
    setBreadCrumbsTrends(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));
  }, [chartData]);

  // stack api

  useEffect(() => {
    const fetchData = async () => {
      // console.log("stack api called!111111111111");

      if (
        JSON.stringify(chartData) !== "{}" &&
        // activeTab === "tab2" &&
        fetchCallTrends &&
        isSubmit &&
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultDaypart &&
        defaultTeam &&
        defaultWeekFlag
      ) {
        console.log("stack api called!");
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/stackchart/`, {
            kpi: defaultKpiFilter,
            match_id: defaultMatchTeamVs,
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            city: defaultCity,
            time: defaultDaypart,
            chartdata: chartData,
            is_all_enabled: IsAllEnabled,
            reset: reset,
            team1: defaultTeam,
            weekend_flag: defaultWeekFlag,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setStackCategories(data.data.categories);
          setStackSeries(data.data);
          setStackNonclickList(data.data.nonclicklist);
          setFetchCallTrends(false);
          // setIsLoading(false);
          setIsAllEnabled(0);
          setIsReset(0);
        } catch (error) {
          console.error("Error fetching data:", error);
          // setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    defaultStartDate,
    defaultCity,
    defaultDaypart,
    defaultEndDate,
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultWeekFlag,
    fetchCallTrends,
    chartData,
    IsAllEnabled,
    reset,
    isSubmit,
    defaultTeam,
  ]);

  // for campaign chart
  useEffect(() => {
    const fetchData = async () => {
      if (
        JSON.stringify(chartDataCamp) !== "{}" &&
        fetchCallCamp &&
        isSubmit &&
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultCity &&
        defaultDaypart &&
        defaultTeam &&
        defaultWeekFlag
        // activeTab === "tab3"
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/campaignchart/`, {
            kpi: defaultKpiFilter,
            match_id: defaultMatchTeamVs,
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            city: defaultCity,
            time: defaultDaypart,
            chartdata: chartDataCamp,
            is_all_enabled: isAllCamp,
            reset: resetCamp,
            team1: defaultTeam,
            weekend_flag: defaultWeekFlag,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data.chartdata);
          setCampData(data.chartdata);
          setFetchCallCamp(false);
          // setIsLoading(false);
          setIsAllCamp(0);
          setResetCamp(0);
        } catch (error) {
          console.error("Error fetching data:", error);
          // setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultStartDate,
    defaultEndDate,
    defaultCity,
    defaultDaypart,
    chartDataCamp,
    isAllCamp,
    resetCamp,
    defaultTeam,
    defaultWeekFlag,
    fetchCallCamp,
    isSubmit,
  ]);

  // for tree button camp
  const DrillAllCamp = () => {
    // let ChartdataTemp = {};
    // for (const filter in Chartdata) {
    //   ChartdataTemp[filter] = Chartdata[filter];
    //   ChartdataTemp[filter].selection = [];
    // }

    // console.log("NotAllow: ", NotAllow);

    // new code ................
    const keysArrayOfChartData = Object.keys(chartDataCamp);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

    result.forEach((item) => {
      return item.push(...campData.nonclicklist);
    });

    // console.log("resulted array: ", result)

    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });

      //  return itemArray;
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData
      .slice(0, keysArrayOfChartData.length - 1)
      .forEach((key, index) => {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTemp[key].selection = payloadArray[index];
        }
      });

    // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);

    // new code ended..............

    setIsAllCamp(1);
    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // for reset button camp
  const ResetAllCamp = () => {
    let ChartdataTemp = {};
    for (const filter in chartDataCamp) {
      ChartdataTemp[filter] = chartDataCamp[filter];
      ChartdataTemp[filter].selection = [];
    }

    setResetCamp(1);
    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // for point click
  const DrillDownCamp = (category) => {
    // console.log("clicked category: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    // console.log("Length: ", Length);

    const allKeysAlreadyInChartdata = Object.keys(chartDataCamp);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);

    let cloneOfChartdata = structuredClone(chartDataCamp);

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartDataCamp(cloneOfChartdata);
    setFetchCallCamp(true);
  };

  // for label click
  const DrillUpCamp = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    const ChartdataTemp = structuredClone(chartDataCamp);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // for down arrow
  const DrillDownAllCamp = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(chartDataCamp);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysAlreadyInChartData.length);
    // console.log("numbers of arrays: ", result);

    result.forEach((item) => {
      return item.push(...campData.nonclicklist);
    });

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsCamp.length;

    const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(
      0,
      keysAlreadyInChartData.length - 1
    );

    // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);

    // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
    //   if(ChartdataTemp.hasOwnProperty(key)) {
    //     // console.log("keyssssssssssssss: ", key);
    //     ChartdataTemp[key].selection = payloadArray[index];
    //   }
    // })

    for (
      let index = 0;
      index < LENGTH && index < chartDataKeysToAddSelection.length;
      index++
    ) {
      const key = chartDataKeysToAddSelection[index];

      if (ChartdataTemp.hasOwnProperty(key)) {
        if (chartDataCamp[key].selection.length < payloadArray[index].length) {
          ChartdataTemp[key].selection = payloadArray[index];
          break;
        } else {
          ChartdataTemp[key].selection = payloadArray[index];
        }
      }
    }

    // new code ended..............

    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  //  prepare BreadCrumbs camp......................................
  useEffect(() => {
    if (JSON.stringify(chartDataCamp) !== "{}") {
      const cloneOfChartdata = structuredClone(chartDataCamp);
      // console.log("cloneOfChartdata: ", cloneOfChartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
      const listToStoreLength = [];
      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        // console.log("list: ", list);
        if (list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          listToStoreLength.push(arr.length);
        }
      }

      // console.log("ARR: ", ARR);

      // console.log("listToStoreLength: ", listToStoreLength);

      let maxNumber = Math.max(...listToStoreLength);

      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }

      // console.log("maxNumber: ", maxNumber);

      setBreadCrumbsCamp(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));
    }
  }, [chartDataCamp]);
  // for up arrow trends
  const DrillUpAllCamp = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(chartDataCamp);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp);
    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsCamp.length;

    // console.log("LENGTH: ", LENGTH);

    const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(
      0,
      keysAlreadyInChartData.length - 1
    );

    // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);

    chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
      if (ChartdataTemp.hasOwnProperty(key)) {
        // console.log("keyssssssssssssss: ", key);
        ChartdataTemp[key].selection = [];
      }
    });

    // new code ended..............

    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // caorousel
  useEffect(() => {
    const payload = {
      kpi: defaultKpiFilter,
      match_id: defaultMatchTeamVs,
      startdate: defaultStartDate,
      enddate: defaultEndDate,
      team1: defaultTeam,
      weekend_flag: defaultWeekFlag,
      city: defaultCity,
      time: defaultDaypart,
      // togid: toggle ? "Incremental" : "Absolute",
    };
    const fetchData = async () => {
      if (
        isSubmit &&
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultTeam &&
        defaultWeekFlag &&
        defaultCity &&
        defaultDaypart
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/carddata/`, payload);
          const data = response.data;
          setCarouselData(data.data);
          // setIsLoading(false);
        } catch (error) {
          // setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultStartDate,
    defaultEndDate,
    defaultTeam,
    defaultWeekFlag,
    defaultCity,
    defaultDaypart,
    // toggle,
    isSubmit,
  ]);

  const [baseLineData, setBaseLineData] = useState([]);
  const [baseLineSeries, setBaseLineSeries] = useState([]);

  // for trends in bottom section.............
  const [multiLineData, setMultiLineData] = useState([]);
  // console.log("multiLineData: ", multiLineData);

  useEffect(() => {
    const fetchData = async () => {
      if (
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultTeam &&
        defaultWeekFlag &&
        defaultCity &&
        defaultDaypart &&
        fetchCallBaseline &&
        isSubmit
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/baselinechart/`, {
            kpi: defaultKpiFilter,
            match_id: defaultMatchTeamVs,
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            team1: defaultTeam,
            weekend_flag: defaultWeekFlag,
            city: defaultCity,
            time: defaultDaypart,
            // togid: toggle ? "Incremental" : "Absolute",
          });
          const data = await response.data; // Use await to get the data
          // console.log("data of baseline");
          setBaseLineData([data]);
          // console.log("seriesData", data.series);
          const filterData = data.series.map((ele) => {
            return {
              name: ele.name,
              type: ele.type,
              data: ele.data,
              tooltip: ele.tooltip,
            };
          });
          setBaseLineSeries(filterData);
          setFetchCallBaseline(false);
          // setIsLoading(false);

          // const filterData = data.series.map((ele)=>{
          //   return
          // })
          // console.log(filterData, "filterData hai");
        } catch (error) {
          console.error("Error fetching data:", error);
          // setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultStartDate,
    defaultEndDate,
    defaultTeam,
    defaultWeekFlag,
    defaultCity,
    defaultDaypart,
    isSubmit,
    fetchCallBaseline,
  ]);

  // line trends chart api call

  useEffect(() => {
    const payload = {
      team1: defaultTeam,
      kpi: defaultKpiFilter,
      match_id: defaultMatchTeamVs,
      startdate: defaultStartDate,
      enddate: defaultEndDate,
      city: defaultCity,
      time: defaultDaypart,
      primary_axis: primaryAxisPayload,
      seconday_axis: [],
      weekend_flag: defaultWeekFlag,
    };

    const fetchData = async () => {
      if (
        isSubmit &&
        fetchCallLine &&
        defaultTeam &&
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultCity &&
        defaultWeekFlag &&
        kpiFilterList &&
        defaultDaypart &&
        primaryAxisPayload
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/trend/`, payload);
          const data = response.data;
          setLineData(data);
          setMultiLineData(data);
          setFetchCallLine(false);
          // setIsLoading(false);
          // console.log(data.data.series,"seriesData")
        } catch (error) {
          // setIsLoading(false);
          // console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [
    defaultTeam,
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultStartDate,
    isSubmit,
    fetchCallLine, // Changed from fetchCallBaseline
    defaultEndDate,
    defaultCity,
    defaultWeekFlag,
    kpiFilterList,
    defaultDaypart,
    primaryAxisPayload,
  ]);

  // for funnel chart
  const [funnelChartData, setFunnelChartData] = useState([]);
  // console.log("funnelChartData: ", funnelChartData);
  const [fetchCallFunnel, setFetchCallFunnel] = useState(true);

  useEffect(() => {
    const payload = {
      team1: defaultTeam,
      kpi: defaultKpiFilter,
      match_id: defaultMatchTeamVs,
      startdate: defaultStartDate,
      enddate: defaultEndDate,
      city: defaultCity,
      time: defaultDaypart,
      primary_axis: primaryAxisPayload,
      seconday_axis: [],
      weekend_flag: defaultWeekFlag,
      tabid: "tab5",
    };

    const fetchData = async () => {
      if (
        isSubmit &&
        fetchCallFunnel &&
        defaultTeam &&
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultCity &&
        defaultWeekFlag &&
        kpiFilterList &&
        defaultDaypart &&
        primaryAxisPayload
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/trend/`, payload);
          const data = response.data;
          setFunnelChartData(data);
          setFetchCallFunnel(false);
          // setIsLoading(false);
          // console.log(data.data.series,"seriesData")
        } catch (error) {
          // setIsLoading(false);
          // console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [
    defaultTeam,
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultStartDate,
    isSubmit,
    fetchCallFunnel, // Changed from fetchCallBaseline
    defaultEndDate,
    defaultCity,
    defaultWeekFlag,
    kpiFilterList,
    defaultDaypart,
    primaryAxisPayload,
  ]);

  // .......................................

  const [matchStackData, setMatchStackData] = useState([]);
  // console.log("matchStackData: ", matchStackData);
  const [fetchCallMatchStack, setFetchCallMatchStack] = useState(true);
  const [isAllMatchStack, setIsAllMatchStack] = useState(0);
  const [isResetMatchStack, setIsResetMatchStack] = useState(0);

  // mathc stack stack api

  // stack chart api

  // drill down for point click in trend

  const DrillDownMatch = (Category) => {
    // for point click
    // console.log("Category: ", Category.series.name);

    const arr = Category.series.name.split("-");
    const Length = arr.length - 1;

    // console.log("Length: ", Length);

    const keysArrayOfChartData = Object.keys(chartDataMatchStack);
    // console.log("keysArrayOfChartData: ", keysArrayOfChartData);

    const keyToAddSelectionFilter = keysArrayOfChartData[Length];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);

    let resultObject = structuredClone(chartDataMatchStack);
    // console.log("resultObject: ", resultObject);

    if (resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection.push(
        Category.series.name
      );
    }

    setChartDataMatchStack(resultObject);
    setFetchCallMatchStack(true);

    // console.log("resultObject: ", resultObject);
  };

  // for tree button
  const DrillAllMatch = () => {
    // for tree button
    let ChartdataTemp = {};
    for (const filter in chartDataMatchStack) {
      ChartdataTemp[filter] = chartDataMatchStack[filter];
      ChartdataTemp[filter].selection = [];
    }

    // new code ................
    const keysArrayOfChartData = Object.keys(chartDataMatchStack);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTrendsTemp = structuredClone(chartDataMatchStack);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

    result.forEach((item) => {
      return item.push(...matchStackData.nonclicklist);
    });

    // console.log("resulted array: ", result)

    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });

      //  return itemArray;
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData
      .slice(0, keysArrayOfChartData.length - 1)
      .forEach((key, index) => {
        if (ChartdataTrendsTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTrendsTemp[key].selection = payloadArray[index];
        }
      });

    // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);

    // new code ended..............

    // let BreadCrumbsTrendsTemp = [];
    // BreadCrumbsTrendsTemp =  Object.keys(chartData);

    // setBreadCrumbsTrends(BreadCrumbsTrendsTemp);

    setIsAllMatchStack(1);
    // setChartdataTrends(ChartdataTemp);
    setChartDataMatchStack(ChartdataTrendsTemp);
    setFetchCallMatchStack(true);
  };

  // for reset button
  const ResetAllMatch = () => {
    // fro reset button
    let ChartdataTemp = {};
    for (const filter in chartDataMatchStack) {
      ChartdataTemp[filter] = chartDataMatchStack[filter];
      ChartdataTemp[filter].selection = [];
    }

    let BreadCrumbsTrendsTemp = [];
    BreadCrumbsTrendsTemp = Object.keys(chartDataMatchStack);

    // setBreadCrumbsTrends([BreadCrumbsTrendsTemp[0]]);
    setIsResetMatchStack(1);
    setIsAllMatchStack(0);
    setChartDataMatchStack(ChartdataTemp);
    setFetchCallMatchStack(true);
  };

  // for down arrow
  const DrillDownAllMatch = () => {
    // for down arrow

    // console.log("is all enabled: ", IsAllEnabled);

    const arr = [];
    const seriesName = matchStackData.series.map((item) => {
      arr.push(item.name.split("-").length);
      return item.name;
    });

    const minLength = Math.min(...arr);

    const payloadSeriesName = seriesName.map((item) => {
      const newItem = item.split("-");

      const a = newItem.slice(0, minLength);
      const b = a.join("-");

      return b;
    });
    // console.log("seriesTrends: ", seriesTrends);
    // console.log("Not allow list: ", NotAllow);
    // console.log("clicked drill down all series payload: ", payloadSeriesName);
    // console.log("series array length: ", minLength - 1);

    const keysArrayOfChartData = Object.keys(chartDataMatchStack);

    // console.log("keysArrayOfChartData: ", keysArrayOfChartData.length, minLength);

    const keyToAddSelectionFilter = keysArrayOfChartData[minLength - 1];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);

    let resultObject = structuredClone(chartDataMatchStack);

    if (
      minLength < keysArrayOfChartData.length &&
      resultObject.hasOwnProperty(keyToAddSelectionFilter)
    ) {
      resultObject[keyToAddSelectionFilter].selection = payloadSeriesName;
    }
    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, minLength + 1));
    setChartDataMatchStack(resultObject);
    setFetchCallMatchStack(true);
  };

  // for up arrow
  const DrillUpAllMatch = () => {
    // console.log("drill up all called: ")

    const arr = [];
    const seriesName = matchStackData.series.map((item) => {
      arr.push(item.name.split("-").length);
      return item.name;
    });

    const maxLength = Math.max(...arr);

    const payloadSeriesName = seriesName.map((item) => {
      const newItem = item.split("-");

      const a = newItem.slice(0, maxLength - 2);
      const b = a.join("-");

      return b;
    });
    // console.log("clicked drill up all series payload: ", payloadSeriesName);
    // console.log("series array length: ", maxLength - 1);

    const keysArrayOfChartData = Object.keys(chartDataMatchStack);
    // console.log("keysArrayOfChartData: ", keysArrayOfChartData);
    const BreadCrumbsTrendsLength = BreadCrumbsMatch.length;

    // console.log("BreadCrumbsTrendsLength: ", BreadCrumbsTrendsLength);
    const keyToAddSelectionFilter = keysArrayOfChartData[maxLength - 3];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);
    // console.log("keys array length: ", keysArrayOfChartData.length);

    const keyToAddSelectionFilterIndex = keysArrayOfChartData.indexOf(
      keyToAddSelectionFilter
    );
    // console.log("keys array index: ", keyToAddSelectionFilterIndex)

    let resultObject = structuredClone(chartDataMatchStack);

    const removeDuplicates = (arr) => {
      const uniqueArray = arr.filter((item, index) => {
        return arr.indexOf(item) === index;
      });

      return uniqueArray;
    };

    // Example usage
    let updatedPayload = removeDuplicates(payloadSeriesName);

    // console.log("updatedPayload in drill up: ", JSON.stringify(updatedPayload));

    if (JSON.stringify(updatedPayload) === '[""]') {
      updatedPayload = [];
    }

    if (keyToAddSelectionFilterIndex === -1) {
      let KEY = keysArrayOfChartData[0];
      if (resultObject.hasOwnProperty(KEY)) {
        resultObject[KEY].selection = updatedPayload;
        // console.log("[Fixed[0].id]: ", [Fixed[0].id]);
      }
      setBreadCrumbsMatch([KEY]);
    } else if (resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection = updatedPayload;
      let start = keyToAddSelectionFilterIndex + 1;
      for (let i = start; i < BreadCrumbsTrendsLength; i++) {
        resultObject[keysArrayOfChartData[i]].selection = [];
      }
      // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, maxLength - 1));
    }
    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, maxLength - 1));
    setChartDataMatchStack(resultObject);
    setFetchCallMatchStack(true);
  };

  // prepare breacrumbs trends
  useEffect(() => {
    const CHART_DATA = [structuredClone(chartDataMatchStack)];
    const [cloneOfChartdata] = CHART_DATA;
    // console.log("cloneOfChartdata: ", cloneOfChartdata);
    const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
    const listToStoreLength = [];
    for (const key in cloneOfChartdata) {
      const list = cloneOfChartdata[key]["selection"];
      // console.log("list: ", list);
      if (list.length === 0) {
        break;
      }
      for (const name of list) {
        const arr = name.split("-");
        // console.log("arr: ", arr);
        listToStoreLength.push(arr.length);
      }
    }
    // console.log("listToStoreLength: ", listToStoreLength);

    let maxNumber = Math.max(...listToStoreLength);

    if (listToStoreLength.length === 0) {
      maxNumber = 0;
    }
    // console.log("maxNumber: ", maxNumber);
    setBreadCrumbsTrends(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));
  }, [chartDataMatchStack]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log("stack api called!111111111111");

      if (
        JSON.stringify(chartDataMatchStack) !== "{}" &&
        // activeTab === "tab2" &&
        fetchCallMatchStack &&
        isSubmit &&
        defaultKpiFilter &&
        defaultMatchTeamVs &&
        defaultStartDate &&
        defaultEndDate &&
        defaultDaypart &&
        defaultTeam &&
        defaultWeekFlag
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${baseURL}/matchstack/`, {
            kpi: defaultKpiFilter,
            match_id: defaultMatchTeamVs,
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            city: defaultCity,
            time: defaultDaypart,
            chartdata: chartDataMatchStack,
            is_all_enabled: isAllMatchStack,
            reset: isResetMatchStack,
            team1: defaultTeam,
            weekend_flag: defaultWeekFlag,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setMatchStackData(data.data);
          setFetchCallMatchStack(false);
          setIsAllMatchStack(0);
          setIsResetMatchStack(0);
        } catch (error) {
          console.error("Error fetching data:", error);
          // setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    defaultStartDate,
    defaultCity,
    defaultDaypart,
    defaultEndDate,
    defaultKpiFilter,
    defaultMatchTeamVs,
    defaultWeekFlag,
    fetchCallMatchStack,
    chartDataMatchStack,
    isAllMatchStack,
    isResetMatchStack,
    isSubmit,
    defaultTeam,
  ]);

  return (
    <>
      {/* <Topbar /> */}
      {isLoading ? <Loader /> : null}
      <Menubar />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ position: "relative", top: 20 }}>
        <Stack
            direction={isMedium||isSmall?"column":"row"}
            justifyContent={"space-between"}
            sx={{ height: isMedium||isSmall ? "" : "780px",
            //  border:"2px solid red" 
            }}
          >
            <Aside
              defaultStartDate={defaultStartDate}
              setDefaultStartDate={setDefaultStartDate}
              defaultEndDate={defaultEndDate}
              setDefaultEndDate={setDefaultEndDate}
              startDate={startDate}
              endDate={endDate}
              // team
              selectTeamFromDropDown={selectTeamFromDropDown}
              setTeampayload={setTeampayload} // team payload
              setDefaultTeam={setDefaultTeam}
              // location
              defaultCity={defaultCity}
              setDefaultCity={setDefaultCity}
              allCities={allCities}
              setCityPayload={setCityPayload} // city payload
              // days

              defaultWeekFlag={defaultWeekFlag}
              setDefaultWeekFlag={setDefaultWeekFlag}
              allWeekFlag={allWeekFlag}
              setDaypartpayload={setDaypartpayload} // day part
              // timings
              defaultDaypart={defaultDaypart}
              setDefaultpart={setDefaultpart}
              allDaypart={allDaypart}
              setWeekflagpayload={setWeekflagpayload} // week flag
              // team1 vs team2
              setMatchTeamVsPayload={setMatchTeamVsPayload} // match id payload
              // kpi filter
              defaultKpiFilter={defaultKpiFilter}
              kpiFilterList={kpiFilterList}
              selectKpiFilterFromDropDown={selectKpiFilterFromDropDown}
              toggle={toggle}
              setToggle={setToggle}
              setPayload={setPayload}
              allTeams={allTeams}
              teamMatchesVs={teamMatchesVs}
              setDefultMatchTeamVs={setDefultMatchTeamVs}
              setTeamId={setTeamId}
              calenderData={calenderData}
              setFetchCall={setFetchCall}
              disabledFlag={disabledFlag}
              setDisabledFlag={setDisabledFlag}
              setFetchCallTrends={setFetchCallTrends}
              setFetchCallCamp={setFetchCallCamp}
              setIsSubmit={setIsSubmit}
              isSubmit={isSubmit}
              setFetchCallBaseline={setFetchCallBaseline}
              setFetchCallLine={setFetchCallLine}
              setFetchCallFunnel={setFetchCallFunnel}
              setFetchCallMatchStack={setFetchCallMatchStack}
            />
            <MainSection
              stackSeries={stackSeries}
              stackCategories={stackCategories}
              stackNonclickList={stackNonclickList}
              DrillDownTrends={DrillDownTrends}
              NotAllow={stackNonclickList}
              DrillAllTrends={DrillAllTrends}
              ResetAllTrends={ResetAllTrends}
              DrillDownAllTrends={DrillDownAllTrends}
              DrillUpAllTrends={DrillUpAllTrends}
              campData={campData}
              // camp charts
              DrillAllCamp={DrillAllCamp}
              ResetAllCamp={ResetAllCamp}
              DrillUpAllCamp={DrillUpAllCamp}
              DrillDownCamp={DrillDownCamp}
              DrillDownAllCamp={DrillDownAllCamp}
              carouselData={carouselData}
              tabNames={tabNames}
              baseLineData={baseLineData}
              baseLineSeries={baseLineSeries}
              // line chart payload
              matchId={defaultMatchTeamVs}
              startdate={defaultStartDate}
              enddate={defaultEndDate}
              city={defaultCity}
              time={defaultDaypart}
              defaultKpiFilter={defaultKpiFilter}
              team={defaultTeam}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              lineData={lineData}
              DrillUpCamp={DrillUpCamp}
              funnelChartData={funnelChartData}
              matchStackData={matchStackData}
              DrillDownMatch={DrillDownMatch}
              DrillAllMatch={DrillAllMatch}
              ResetAllMatch={ResetAllMatch}
              DrillDownAllMatch={DrillDownAllMatch}
              DrillUpAllMatch={DrillUpAllMatch}
            />
          </Stack>
          {/* stack chart start */}
          {stackSeries && stackCategories && tabId.includes("tab2") ? (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "bgColor",
                padding: "1rem",
                borderRadius: "20px",
                marginTop: "1rem",
                position: "relative",
              }}
            >
              <Typography
                sx={{ color: "#d6ff41", position: "absolute", right: isSmall||isMedium?"30%":"46%" }}
              >
                Daily Attribution
              </Typography>

              <StackContainer
                stackCategories={stackCategories}
                stackSeries={stackSeries}
                NotAllow={stackNonclickList}
                DrillDownTrends={DrillDownTrends}
                DrillAllTrends={DrillAllTrends}
                ResetAllTrends={ResetAllTrends}
                DrillDownAllTrends={DrillDownAllTrends}
                DrillUpAllTrends={DrillUpAllTrends}
              />
            </Box>
          ) : null}

          {/* campaingn chart start */}

          {campData && tabId.includes("tab3") ? (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "bgColor",
                padding: "1rem",
                borderRadius: "20px",
                marginTop: "1rem",
                position: "relative",
              }}
            >
              <Typography
                sx={{ color: "#d6ff41", position: "absolute", right: isSmall||isMedium?"10%": "46%" }}
              >
                Channel Attribution
              </Typography>

              <CampaignContainer
                campData={campData}
                DrillAllCamp={DrillAllCamp}
                ResetAllCamp={ResetAllCamp}
                DrillUpAllCamp={DrillUpAllCamp}
                DrillDownCamp={DrillDownCamp}
                DrillDownAllCamp={DrillDownAllCamp}
                DrillUpCamp={DrillUpCamp}
              />
            </Box>
          ) : null}

          {/* campaign chart end */}

          {multiLineData && tabId.includes("tab4") ? (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "bgColor",
                padding: "1rem",
                borderRadius: "20px",
                marginTop: "1rem",
                textAlign: "center",
              }}
            >
              <Typography sx={{ color: "#d6ff41" }}>Trends</Typography>
              <LineChart multiLineData={multiLineData} />
            </Box>
          ) : null}

          {funnelChartData && tabId.includes("tab5") ? (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "bgColor",
                padding: "1rem",
                borderRadius: "20px",
                marginTop: "1rem",
                textAlign: "center",
              }}
            >
              <Typography sx={{ color: "#d6ff41" }}>
                Funnel Ratio
              </Typography>
              <FunnelChart funnelChartData={funnelChartData} />
            </Box>
          ) : null}

          {matchStackData && tabId.includes("tab6") ? (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "bgColor",
                padding: "1rem",
                borderRadius: "20px",
                marginTop: "1rem",
                position: "relative",
              }}
            >
              <Typography
                sx={{ color: "#d6ff41", position: "absolute", right: isSmall||isMedium?"10%": "46%" }}
              >
                Match Attribution
              </Typography>
              <MatchStackChartContainer
                matchStackData={matchStackData}
                DrillDownMatch={DrillDownMatch}
                DrillAllMatch={DrillAllMatch}
                ResetAllMatch={ResetAllMatch}
                DrillDownAllMatch={DrillDownAllMatch}
                DrillUpAllMatch={DrillUpAllMatch}
              />
            </Box>
          ) : null}
        </div>
      </ThemeProvider>
    </>
  );
};

export default App;
