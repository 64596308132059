import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
const MultiLineChart = (props) => {
  //   console.log("data: ", props.multiLineData);

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));


  const options = {
    chart: {
      zoomType: "xy",
      type: "spline",
      height: 500,
      backgroundColor: "black",
      // backgroundColor: "#1c2427", // Set your desired background color her
      resetZoomButton: {
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
    },
    title: {
      text: null,
    },

    xAxis: {
      labels: {
        style: {
          color: "#FFFFFF", // Set color for x-axis labels
        },
      },
      categories: props.multiLineData?.data?.chartdata?.xAxis?.Category,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null, // Set Y-axis title to null
      },
      labels: {
        style: {
          color: "#FFFFFF", // Set color for Y-axis labels
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal", // Set the layout to vertical
      align: "right", // Align the legend to the right
      verticalAlign: "top", // Center the legend vertically
      itemStyle: {
        color: "#FFFFFF", // Set the color of legend items
      },
      labelFormatter: function () {
        return this.userOptions.display_name || this.name;
      },
    },
    // tooltip: {
    //   formatter: function () {
    //     return (
    //       "<b>" +
    //       (this.point.series.userOptions.display_name ||
    //         this.point.series.name) +
    //       "</b>: " +
    //       this.y
    //     );
    //   },
    // },
    tooltip: {
      shared: true,
      formatter: function () {
        var points = this.points || [];
        var tooltip =
          "<b>" + (points[0] ? points[0].x : "Category") + "</b><br/>";
        points.forEach(function (point) {
          tooltip +=
            '<span style="color:' +
            point.series.color +
            '">\u25CF</span> ' +
            (point.series.userOptions.display_name || point.series.name) +
            ": <b>" +
            point.y +
            "</b><br/>";
        });
        return tooltip;
      },
    },

    series: props.multiLineData?.data?.series,
  };

  return (
    <div style={{
      height: "100%",
      // border:"2px solid red",
      overflowx: (isSmall||isMedium)?"auto":"", /* Enable horizontal scrollbar */
      overflowy:(isSmall||isMedium)?"hidden":"", /* Hide vertical scrollbar */
      whitespace:(isSmall||isMedium)?"nowrap":"", /* Prevent line breaks */
      overflow:(isSmall||isMedium)?"scroll":""
    }}>
      <div style={{ height: "100%",
  width: (isMedium||isSmall)?"800px":"",
  overflow:(isMedium||isSmall)?"scroll":"",}}>
      
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
    </div>
  );
};

export default MultiLineChart;
