import React, { useState, useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const FunnelChart = (props) => {
  //   console.log("data: ", props.multiLineData);

  const [divHeight, setDivHeight] = useState("");
  console.log("divHeight funnel chart: ", divHeight);

  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const options = {
    chart: {
      zoomType: "xy",
      type: "spline",
      height: divHeight,
      backgroundColor: "black",
      // backgroundColor: "#1c2427", // Set your desired background color her
      resetZoomButton: {
        theme: {
          fill: "red",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
    },
    title: {
      text: null,
    },

    xAxis: {
      labels: {
        style: {
          color: "#FFFFFF", // Set color for x-axis labels
        },
      },
      categories: props.funnelChartData?.data?.chartdata?.xAxis?.Category,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null, // Set Y-axis title to null
      },
      labels: {
        style: {
          color: "#FFFFFF", // Set color for Y-axis labels
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal", // Set the layout to vertical
      align: "right", // Align the legend to the right
      verticalAlign: "top", // Center the legend vertically
      itemStyle: {
        color: "#FFFFFF", // Set the color of legend items
      },
      labelFormatter: function () {
        return this.userOptions.display_name || this.name;
      },
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          (this.point.series.userOptions.display_name ||
            this.point.series.name) +
          "</b>: " +
          this.y + "%"
        );
      },
    },
    series: props.funnelChartData?.data?.series,
  };

  return (
    <div ref={divRef} style={{ height: "100%" }}>
      {divHeight && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default FunnelChart;
