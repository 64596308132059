import React from "react";
import { Stack } from "@mui/material";

const Channels = () => {
  return (
    <Stack
      sx={{
        width: "55%",
        height: "100%",
        // border: "1px solid yellow",
        backgroundColor: "bgColor",
        borderRadius: "20px",
        padding: "1rem",
      }}
    >
      <Stack sx={{ height: "100%", border: "1px solid red" }}></Stack>
    </Stack>
  );
};

export default Channels;
