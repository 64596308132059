import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";

import LogoMark from "../images/logo11.png";
import LogoutIcon from "@mui/icons-material/Logout";

const Menubar = () => {
  const location = useLocation();
  const tabs = JSON.parse(localStorage.getItem("tabs"));
  console.log("tabs: ", tabs);

  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#1c2427",
        padding: "0 1rem",
        position: "sticky",
        top: 10,
        zIndex: 99999,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: 20 }}>
          {tabs.map((item) => {
            return (
              <div key={item.value} style={{}}>
                <div
                  style={{
                    fontSize: "0.85rem",
                    color:
                      location.pathname === `/${item.value}`
                        ? "#d6ff41"
                        : "white",
                    cursor: "pointer",
                    borderBottom:
                      location.pathname === `/${item.value}`
                        ? "2px solid #d6ff41"
                        : "none",
                  }}
                  onClick={() => navigate(`/${item.value}`)}
                >
                  {item.label}
                </div>
              </div>
            );
          })}
        </div>

        {/* ########################### */}
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <img
            height={"40px"}
            src={LogoMark}
            alt=""
            style={{ marginRight: 10, cursor: "pointer" }}
          />
          <LogoutIcon
            onClick={() => navigate("/logout")}
            title="Logout"
            sx={{ color: "#d6ff41", cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Menubar;
